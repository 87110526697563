

















































import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
import { mapState, mapActions } from "pinia";
import { useContentStore } from "@/store/contentStore";

import ContentItemSummary from "@/components/contentItems/ContentItemSummary.vue";
import { ContentItemSummaryResult, ContentsClient, FolioClient, FolioContentItemResult, RemoveFolioClippingModel } from "@/api/DoceoApi";

import CreateFolioDialog from "@/components/contentItems/folios/CreateFolioDialog.vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

/**
 * This component is used to display the full content item display for folios
 */
export default Vue.extend({
  name: "Discover",
  components: { ContentItemSummary, DoceoIcon, CreateFolioDialog },
  props: {
    contentItem: Object as PropType<FolioContentItemResult>,
  },
  data: () => ({
    isLoading: false,
    updatedContentItem: {} as FolioContentItemResult,
    clippings: [] as ContentItemSummaryResult[],
    isEditFolioDialogOpen: false, // Whether the edit folio dialog is open or not
    DOCEO_ICONS,
  }),
  created() {
    this.updatedContentItem = Object.assign({}, this.contentItem);
  },
  async mounted() {
    this.loadItems();
  },
  methods: {
    ...mapActions(useContentStore, ["fetchSummaries"]),
    /**
     * If a clipping has been removed, filter it out of the list of items being displayed to the user.
     * This should only be called when the server succesfully removed the item
     */
    clippingRemoved(clippingToRemove: RemoveFolioClippingModel) {
      this.clippings = this.clippings.filter((e) => e.contentItemId != clippingToRemove.contentItemId);
    },
    /**
     * Go back to the folios page
     */
    goBack() {
      this.$router.go(-1);
    },
    /**
     * Close the edit folio dialog
     */
    closeFolioDialog() {
      this.isEditFolioDialogOpen = false;
    },
    /**
     * The edit folio has been confirmed and we can close the dialog
     */
    async editFolioConfirmed() {
      let client = new ContentsClient();
      this.updatedContentItem = await client.getContentItem(this.updatedContentItem.contentItemId) as FolioContentItemResult;
      this.isEditFolioDialogOpen = false;
    },
    /**
     * Open the dialog to edit a folio
     */
    editFolioClicked() {
      this.isEditFolioDialogOpen = true;
    },
    /**
     * Load the clippings that belong to the folio content item
     */
    async loadItems() {
      this.isLoading = true;
      const folioClient = new FolioClient();
      this.clippings = await folioClient.getFolioClippings(this.updatedContentItem.contentItemId);
      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters(["userId"]),
    ...mapState(useContentStore, ["summaries", "newContentAdded"]),
    newestSummaries(): ContentItemSummaryResult[] {
      var x = this.summaries as ContentItemSummaryResult[];
      return x;
    },
    /**
     * Return the identifier of the folio
     */
    folioId(): string {
      return this.updatedContentItem.contentItemId;
    },
    /**
     * Flag to indicate if the folio is created by the logged in user
     */
    userIsAuthor(): boolean {
      return this.updatedContentItem.creator?.userId == this.userId; // TODO: Actual logic for this
    },
  },
  watch: {
    contentItem() {
      this.updatedContentItem = this.contentItem;
    },
  },
});
