










import { ContentItemResult, ContentsClient } from "@/api/DoceoApi";
import ContentItem from "@/components/contentItems/ContentItem.vue";
import Vue from "vue";
import { useContentStore } from "@/store/contentStore";
import { useAnalyticsStore, EventTimeCategory, EventTimeName } from "@/store/analyticsStore";

import { mapActions } from "pinia";

export default Vue.extend({
  components: { ContentItem },
  metaInfo() {
    return {
      link: [{ rel: "canonical", href: `${process.env.VUE_APP_BASE_URL}${this.$route.path}` }],
    };
  },
  props: {
    contentItemId: String,
  },

  watch: {
    $route(to, from) {
      const id = to.params.id;
      this.loadItems();
    },
  },

  data() {
    return {
      contentItem: undefined as ContentItemResult | undefined,

      /**
       * The version of the content item being looked at.
       */
      currentVersionId: undefined as string | undefined,
    };
  },

  async beforeMount() {
    this.loadItems();
  },

  computed: {},

  methods: {
    ...mapActions(useContentStore, ["fetchVersionHistory"]),
    ...mapActions(useAnalyticsStore, ["submitEventTimer"]),

    async loadItems() {
      const contentsClient = new ContentsClient();
      const analyticsStore = useAnalyticsStore();
      let startTime = new Date();
      this.contentItem = await contentsClient.getContentItemVersion(this.contentItemId);
      analyticsStore.submitEventTimer(startTime, new Date(), EventTimeName.loadContentItem, EventTimeCategory.content);
      this.currentVersionId = this.contentItem.versionId;
    },
  },
});
