




































































































import {
  ContentsClient,
  DrugBrandContentItemResult,
  DrugStatus,
  ContentItemRelatedResult,
  ContentItemType,
  Attachment,
  DrugContentItemResult,
  DrugContentItemVersion,
} from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import { mapActions } from "pinia";
import BaseContentItem from "@/components/contentItems/BaseContentItem.vue";
import DoceoImage from "@/components/DoceoImage.vue";
import DOCEO_ICONS from "@/constants/icons";
import ContentItemDate from "@/components/contentItems/ContentItemDate.vue";
import RelatedItem from "@/components/contentItems/drugs/RelatedItem.vue";
import { useAttachmentStore } from "@/store/attachmentStore";

/**
 * This component is used to display the full content item display for drugs
 */
export default Vue.extend({
  components: {
    BaseContentItem,
    DoceoImage,
    ContentItemDate,
    RelatedItem,
    DoceoIcon,
  },

  props: {
    contentItem: Object as PropType<DrugBrandContentItemResult>,
  },

  data: () => ({
    relatedItems: [] as ContentItemRelatedResult[],
    articleContentType: ContentItemType.Article,
    trialContentType: ContentItemType.Trial,
    decisionContentType: ContentItemType.Decision,
    DOCEO_ICONS: DOCEO_ICONS,
    drugItemsHeaders: [
      {
        text: "DIN",
        value: "din",
        sortable: false,
      },
      { text: "Drug Code", value: "drugCode", sortable: false },
      { text: "Last updated", value: "createdOn", sortable: false },
      { text: "Status", value: "drugStatus", sortable: false },
      { text: "Ingredients", value: "drugIngredients", sortable: false },
      { text: "", value: "attachments", width: "110px", sortable: false },
    ],
    drugItems: [] as DrugContentItemResult[],
    loadingDrugItems: false,
  }),
  async created() {
    this.fetchDrugItems();
    this.fetchRelated();
  },
  computed: {
    orderedDrugs(): DrugContentItemResult[] {
      if (this.drugItems) {
        let sorted = [...this.drugItems].sort((e) => {
          return e.drugIngredients ? e.drugIngredients[0].strength ?? 0 : 0;
        });
        return sorted;
      }
      return [];
    },
    upcomingClinicalTrialSearchTerm(): string {
      return `${this.contentItem.name} type:upcoming${ContentItemType.Trial}`;
    },
    recentClinicalTrialSearchTerm(): string {
      return `${this.contentItem.name} type:recent${ContentItemType.Trial}`;
    },
    decisionSearchTerm(): string {
      return `${this.contentItem.name} type:${ContentItemType.Decision}`;
    },
    articleSearchTerm(): string {
      return `${this.contentItem.name} type:${ContentItemType.Article}`;
    },
    /**
     * Filter list of related articles
     */
    relatedRecentArticles(): ContentItemRelatedResult[] {
      return this.relatedItems.filter((e) => e.type == ContentItemType.Article);
    },
    /**
     * Filter list of related trials
     */
    relatedRecentTrials(): ContentItemRelatedResult[] {
      return this.relatedItems.filter((e) => e.type == ContentItemType.Trial && e.isRecent);
    },
    /**
     * Filter list of related trials
     */
    relatedUpcomingTrials(): ContentItemRelatedResult[] {
      return this.relatedItems.filter((e) => e.type == ContentItemType.Trial && !e.isRecent);
    },
    /**
     * Filter list of related decisions
     */
    relatedRecentDecisions(): ContentItemRelatedResult[] {
      return this.relatedItems.filter((e) => e.type == ContentItemType.Decision);
    },
  },
  methods: {
    ...mapActions(useAttachmentStore, ["getAttachmentAsync"]),
    async fetchRelated() {
      const contentsClient = new ContentsClient();
      const searchTerm = this.contentItem.title;
      this.relatedItems = await contentsClient.getRelatedContentItems(searchTerm);
    },
    async fetchDrugItems() {
      this.loadingDrugItems = true;
      const contentsClient = new ContentsClient();
      let items = await contentsClient.getDrugItems(this.contentItem.versionId);
      this.drugItems = items;
      this.loadingDrugItems = false;
    },
    /**
     * Return the color appropriate based on the status
     */
    drugStatusColor(drugStatus): string {
      switch (drugStatus) {
        case DrugStatus.Approved:
          return "green";
        case DrugStatus.Marketed:
          return "orange"; // Need color
        case DrugStatus.CancelledPreMarket:
          return "purple"; // Need color
        case DrugStatus.CancelledPostMarket:
          return "yellow"; // Need color
        case DrugStatus.Dormant:
          return "red"; // Need color
        default:
          return "Unknown"; // Need color
      }
    },
    /**
     * Return a string representation of the drug status
     */
    drugStatus(drugStatus): string {
      switch (drugStatus) {
        case DrugStatus.Approved:
          return "Approved";
        case DrugStatus.Marketed:
          return "Marketed";
        case DrugStatus.CancelledPreMarket:
          return "Cancelled PreMarket";
        case DrugStatus.CancelledPostMarket:
          return "Cancelled PostMarket";
        case DrugStatus.Dormant:
          return "Dormant";
        default:
          return "Unknown";
      }
    },
    async downloadAttachment(attachment: Attachment) {
      let attachmentData = await this.getAttachmentAsync(attachment.id);
      if (!attachmentData?.data) return;
      this.downloadBlob(attachmentData.data, attachment.fileName);
    },

    downloadBlob(blob: Blob, filename: string | null | undefined) {
      let url = window.URL.createObjectURL(blob);
      console.log(url);
      console.log(navigator.userAgent);

      if (navigator.userAgent.indexOf("Firefox") != -1) {
        window.open(url, "_target");
      } else {
        let a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("target", "_blank");
        a.setAttribute("download", filename ?? "");
        a.click();
      }
      window.URL.revokeObjectURL(url); // prevent potential memory leaks
    },
  },
});
