













import { ContentItemResult, ContentItemType } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import ArticleContentItem from "./articles/ArticleContentItem.vue";
import DrugContentItem from "./drugs/DrugContentItem.vue";
import DrugBrandContentItem from "./drugBrands/DrugBrandContentItem.vue";
import TrialContentItem from "./trials/TrialContentItem.vue";
import FolioContentItem from "./folios/FolioContentItem.vue";
import DecisionContentItem from "./decisions/DecisionContentItem.vue";
import UserArticleContentItem from "./userContent/articles/UserArticleContentItem.vue";
import DraftUserArticleContentItem from "./drafts/articles/DraftUserArticleContentItem.vue";
import UserExternalLinkContentItem from "./userContent/externalLinks/UserExternalLinkContentItem.vue";
import DraftUserExternalLinkContentItem from "./drafts/externalLinks/DraftUserExternalLinkContentItem.vue";
import UnknownContentItem from "./UnknownContentItem.vue";
export default Vue.extend({
  props: {
    contentItem: Object as PropType<ContentItemResult>,
  },

  components: {
    ArticleContentItem,
    DrugContentItem,
    DrugBrandContentItem,
    TrialContentItem,
    FolioContentItem,
    DecisionContentItem,
    UserArticleContentItem,
    DraftUserArticleContentItem,
    UserExternalLinkContentItem,
    DraftUserExternalLinkContentItem,
    UnknownContentItem,
  },

  computed: {
    /**
     * Render the appropriate component based on the type of Content Item.
     */
    componentType(): object | undefined {
      switch (this.contentItem.contentItemType) {
        case ContentItemType.Article:
          return ArticleContentItem;
        case ContentItemType.DrugBrandGroup:
          return DrugBrandContentItem;
        case ContentItemType.Drug:
          return DrugContentItem;
        case ContentItemType.Trial:
          return TrialContentItem;
        case ContentItemType.Folio:
          return FolioContentItem;
        case ContentItemType.Decision:
          return DecisionContentItem;
        case ContentItemType.UserArticle:
          return UserArticleContentItem;
        case ContentItemType.DraftUserArticle:
          return DraftUserArticleContentItem;
        case ContentItemType.UserExternalLink:
          return UserExternalLinkContentItem;
        case ContentItemType.DraftUserExternalLink:
          return DraftUserExternalLinkContentItem;
        default:
          return UnknownContentItem;
      }
    },
  },
});
