
































































































































































































import { DecisionContentItemResult } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import BaseContentItem from "@/components/contentItems/BaseContentItem.vue";
import ContentItemDate from "@/components/contentItems/ContentItemDate.vue";
import DoceoImage from "@/components/DoceoImage.vue";

/**
 * This component is used to display the full content item display for decisions
 */
export default Vue.extend({
  components: { BaseContentItem, DoceoImage, ContentItemDate },

  props: {
    contentItem: Object as PropType<DecisionContentItemResult>,
  },

  data: () => ({}),
  methods: {
    getTitle(title: string | undefined) {
      // {{ getItem("type of submission") }} - {{ getItem("decision") }}
      return (title ?? "").replace(`: ${this.getItemString("type of submission")} - ${this.getItemString("decision")}`, "");
    },
    /**
     * Seperate the summary into a list of paragraphs (Split on the <p> tag's that are returned)
     * This will also strip out all HTML tags
     */
    paragraphs(original: string): string[] {
      if (original) {
        let messages = original.split("</p>");
        return messages;
        // return messages.map((e) => this.stripHTML(e));
      }
      return [];
    },
    /**
     * Strips out the HTML from articles
     */
    stripHTML(original: string): string {
      return original
        .replace(/<[^>]*>?/gm, "")
        .replaceAll("&nbsp;", " ")
        .replaceAll("&gt;", ">")
        .replaceAll("&lt;", "<");
    },
    getItemString(key: string) {
      return this.getItem(key) ?? "";
    },
    getItem(key: string): string | null {
      if (this.contentItem && this.contentItem.decisionItems) {
        var decisionItem = this.contentItem.decisionItems.filter((e) => e.name?.toLowerCase() == key);
        return decisionItem.length > 0 ? this.stripHTML(decisionItem[0].contents ?? "") ?? null : null;
      }
      return null;
    },
  },
  computed: {
    /**
     * Determine if the content is either "Promoted By" or "Voice Of". This is used for styling differently for sponsered content
     */
    isPromotedByOrVoiceOf(): boolean {
      return this.isPromotedBy || this.isVoiceOf;
    },
    /**
     * Determine if the content is "Promoted By" content. This is used mainly for styling purposes.
     */
    isPromotedBy(): boolean {
      return this.contentItem.promotedBy != undefined;
    },
    /**
     * Determine if the content is "Voice Of" content. This is used mainly for styling purposes.
     */
    isVoiceOf(): boolean {
      return this.contentItem.voiceOf != undefined;
    },

    /**
     * Checks to see if the content item has an attachment
     */
    attachmentId(): String | null {
      if (this.contentItem.attachments && this.contentItem.attachments.length > 0) {
        return this.contentItem.attachments[0].id;
      }
      return null;
    },
  },
});
