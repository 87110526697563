




import { ContentItemResult } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";

export default Vue.extend({
  props: {
    contentItem: Object as PropType<ContentItemResult>,
  },
});
