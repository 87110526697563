









































































































import { ContentsClient, DrugContentItemResult, DrugStatus, ContentItemRelatedResult, ContentItemType } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import BaseContentItem from "@/components/contentItems/BaseContentItem.vue";
import DoceoImage from "@/components/DoceoImage.vue";
import ContentItemDate from "@/components/contentItems/ContentItemDate.vue";
import RelatedItem from "@/components/contentItems/drugs/RelatedItem.vue";
import { offset } from "@popperjs/core";

/**
 * This component is used to display the full content item display for drugs
 */
export default Vue.extend({
  components: { BaseContentItem, DoceoImage, ContentItemDate, RelatedItem },

  props: {
    contentItem: Object as PropType<DrugContentItemResult>,
  },

  data: () => ({
    relatedItems: [] as ContentItemRelatedResult[],
    articleContentType: ContentItemType.Article,
    trialContentType: ContentItemType.Trial,
    decisionContentType: ContentItemType.Decision,
  }),
  async created() {
    const contentsClient = new ContentsClient();
    const searchTerm = this.contentItem.title;
    this.relatedItems = await contentsClient.getRelatedContentItems(searchTerm);
  },
  computed: {
    /**
     * Filter list of related articles
     */
    relatedRecentArticles(): ContentItemRelatedResult[] {
      return this.relatedItems.filter((e) => e.type == ContentItemType.Article);
    },
    /**
     * Filter list of related trials
     */
    relatedRecentTrials(): ContentItemRelatedResult[] {
      return this.relatedItems.filter((e) => e.type == ContentItemType.Trial && e.isRecent);
    },
    /**
     * Filter list of related trials
     */
    relatedUpcomingTrials(): ContentItemRelatedResult[] {
      return this.relatedItems.filter((e) => e.type == ContentItemType.Trial && !e.isRecent);
    },
    /**
     * Filter list of related decisions
     */
    relatedRecentDecisions(): ContentItemRelatedResult[] {
      return this.relatedItems.filter((e) => e.type == ContentItemType.Decision);
    },
    /**
     * Return a string representation of the drug status
     */
    drugStatus(): string {
      // export enum DrugStatus {
      switch (this.contentItem.drugStatus) {
        case DrugStatus.Approved:
          return "Approved";
        case DrugStatus.Marketed:
          return "Marketed";
        case DrugStatus.CancelledPreMarket:
          return "Cancelled PreMarket";
        case DrugStatus.CancelledPostMarket:
          return "Cancelled PostMarket";
        case DrugStatus.Dormant:
          return "Dormant";
        default:
          return "Unknown";
      }
    },
    /**
     * Return the color appropriate based on the status
     */
    statusColor(): string {
      // export enum DrugStatus {
      switch (this.contentItem.drugStatus) {
        case DrugStatus.Approved:
          return "green";
        case DrugStatus.Marketed:
          return "orange"; // Need color
        case DrugStatus.CancelledPreMarket:
          return "purple"; // Need color
        case DrugStatus.CancelledPostMarket:
          return "yellow"; // Need color
        case DrugStatus.Dormant:
          return "red"; // Need color
        default:
          return "Unknown"; // Need color
      }
    },
  },
  methods: {},
});
