var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseContentItem',{attrs:{"contentItem":_vm.contentItem},scopedSlots:_vm._u([{key:"details",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.drugItemsHeaders,"items":_vm.orderedDrugs,"loading":_vm.loadingDrugItems,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$dayjs(item.createdOn).format("MMMM DD, YYYY")))])]}},{key:"item.attachments",fn:function(ref){
var item = ref.item;
return [(item.sourceUrl)?_c('v-btn',{attrs:{"icon":"","href":item.sourceUrl,"target":"_blank","rel":"noopener","ripple":false}},[_c('DoceoIcon',{attrs:{"icon":_vm.DOCEO_ICONS.LINK,"cursor":"pointer","alt":"Go To Link"}})],1):_vm._e(),(item.attachments && item.attachments.length > 0)?[_c('v-btn',{attrs:{"icon":"","target":"_blank","rel":"noopener","ripple":false},on:{"click":function($event){return _vm.downloadAttachment(item.attachments[0])}}},[_c('DoceoIcon',{attrs:{"icon":_vm.DOCEO_ICONS.ATTACHMENT,"cursor":"pointer","alt":"Go To Link"}})],1)]:_vm._e()]}},{key:"item.drugStatus",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.drugStatusColor(item.drugStatus)}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v(" "+_vm._s(_vm.drugStatus(item.drugStatus))+" ")]}},{key:"item.drugIngredients",fn:function(ref){
var item = ref.item;
return _vm._l((item.drugIngredients),function(ingredient){return _c('v-row',{key:ingredient.name,attrs:{"dense":""}},[_c('v-col',[_vm._v(" "+_vm._s(ingredient.name)+": Dosage "+_vm._s(ingredient.dosage)+" "+_vm._s(ingredient.dosageUnit)+" Strength "+_vm._s(ingredient.strength)+" "+_vm._s(ingredient.strengthUnit)+" ")])],1)})}},{key:"item.attachment",fn:function(ref){
var item = ref.item;
return _vm._l((item.drugIngredients),function(ingredient){return _c('v-row',{key:ingredient.name,attrs:{"dense":""}},[_c('v-col',[_vm._v(" "+_vm._s(ingredient.name)+": Dosage "+_vm._s(ingredient.dosage)+" "+_vm._s(ingredient.dosageUnit)+" Strength "+_vm._s(ingredient.strength)+" "+_vm._s(ingredient.strengthUnit)+" ")])],1)})}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.relatedRecentArticles.length > 0)?_c('RelatedItem',{attrs:{"title":"Recent Articles","items":_vm.relatedRecentArticles,"searchTerm":_vm.articleSearchTerm,"contentType":_vm.articleContentType}}):_vm._e(),(_vm.relatedRecentTrials.length > 0)?_c('RelatedItem',{attrs:{"title":"Recent Clinical Trials","items":_vm.relatedRecentTrials,"searchTerm":_vm.recentClinicalTrialSearchTerm,"contentType":_vm.trialContentType}}):_vm._e(),(_vm.relatedUpcomingTrials.length > 0)?_c('RelatedItem',{attrs:{"title":"Upcoming Clinical Trials","items":_vm.relatedUpcomingTrials,"searchTerm":_vm.upcomingClinicalTrialSearchTerm,"contentType":_vm.trialContentType}}):_vm._e(),(_vm.relatedRecentDecisions.length > 0)?_c('RelatedItem',{attrs:{"title":"Recent Decisions","items":_vm.relatedRecentDecisions,"searchTerm":_vm.decisionSearchTerm,"contentType":_vm.decisionContentType}}):_vm._e()],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }