































































import { ArticleContentItemResult, AttachmentClient, FileResponse } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import BaseContentItem from "@/components/contentItems/BaseContentItem.vue";
import ContentItemDate from "@/components/contentItems/ContentItemDate.vue";
import DoceoImage from "@/components/DoceoImage.vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

/**
 * This component is used to display the full content item display for articles
 */
export default Vue.extend({
  components: { BaseContentItem, DoceoImage, DoceoIcon, ContentItemDate },

  props: {
    contentItem: Object as PropType<ArticleContentItemResult>,
  },

  data: () => ({
    DOCEO_ICONS,
  }),

  computed: {
    /**
     * Return the list of authors seperated by commas.
     */
    authors(): string | null {
      let authors = this.contentItem.authors;
      if (authors && authors.length > 0) {
        let authorNames = authors.map((e) => e.author);
        return authorNames.join(", ");
      }
      return null;
    },
  },
  methods: {},
});
