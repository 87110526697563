









































































import { ContentItemResult, TrialContentItemResult, TrialLocation2 } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import BaseContentItem from "@/components/contentItems/BaseContentItem.vue";
import ContentItemDate from "@/components/contentItems/ContentItemDate.vue";
import DoceoImage from "@/components/DoceoImage.vue";
import DoceoIcon from "@/components/DoceoIcon.vue";
import DOCEO_ICONS from "@/constants/icons";

/**
 * This component is used to display the full content item display for trials
 */
export default Vue.extend({
  components: { BaseContentItem, DoceoImage, DoceoIcon, ContentItemDate },

  props: {
    contentItem: Object as PropType<TrialContentItemResult>,
  },

  data: () => ({}),
  computed: {
    contentItemBase(): ContentItemResult {
      return this.contentItem as ContentItemResult;
    },
    /**
     * Determine an appropriate message for age range based on the min/max age.
     */
    ageRange(): string {
      let message = "Age Range: ";
      let minAgeInYears = this.contentItem.minAgeInYears;
      let maxAgeInYears = this.contentItem.maxAgeInYears;

      if (minAgeInYears > 0 && maxAgeInYears > 0) {
        return `${message} ${minAgeInYears} - ${maxAgeInYears}`;
      } else if (minAgeInYears <= 0 && maxAgeInYears > 0) {
        return `${message} Up to ${maxAgeInYears}`;
      } else if (minAgeInYears > 0 && maxAgeInYears <= 0) {
        return `${message} ${minAgeInYears}+`;
      } else if (minAgeInYears <= 0 && maxAgeInYears <= 0) {
        return `No age range specified`;
      } else {
        return `${message} Unkown`;
      }
    },

    trialStatus(): string {
      return this.contentItem.trialStatusType?.name ?? "Unknown";
    },
    /**
     * Determine an appropriate icon based on the gender of the trial
     */
    genderIcon(): string {
      return DOCEO_ICONS.GENDER;
    },
    /**
     * Return the color appropriate based on the status
     */
    statusColor(): string {
      // export enum DrugStatus {
      switch (this.contentItem.trialStatusType?.name?.toLowerCase()) {
        case "available":
          return "green";
        case "enrolling by invitation":
          return "green"; // Need color
        case "active, not recruiting":
          return "grey"; // Need color
        case "withheld":
          return "yellow"; // Need color
        case "suspended":
          return "red"; // Need color
        case "not yet recruiting":
          return "yellow"; // Need color
        case "withdrawn":
          return "red"; // Need color
        case "terminated":
          return "red"; // Need color
        case "unknown status":
          return "red"; // Need color
        case "completed":
          return "grey"; // Need color
        case "approved for marketing":
          return "red"; // Need color
        case "recruiting":
          return "green"; // Need color
        default:
          return "orange"; // Need color
      }
    },
  },
  methods: {
    /**
     * Generate the string representation of the trial location
     */
    getLocation(location: TrialLocation2): string {
      var locationString = "";
      if (location.address) locationString += `${location.address}, `;
      if (location.city) locationString += `${location.city}, `;
      if (location.state) locationString += `${location.state}, `;
      if (location.country) locationString += `${location.country}, `;

      locationString = locationString.replace(/,\s*$/, ""); // Remove the last comma and any whitespace after it
      return locationString;
    },
  },
});
