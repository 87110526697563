































import { ContentsClient, DrugContentItemResult, DrugStatus, ContentItemRelatedResult, ContentItemType } from "@/api/DoceoApi";
import Vue, { PropType } from "vue";
import BaseContentItem from "@/components/contentItems/BaseContentItem.vue";
import DoceoImage from "@/components/DoceoImage.vue";
import ContentItemDate from "@/components/contentItems/ContentItemDate.vue";
import { offset } from "@popperjs/core";

/**
 * This component is used to display the full content item display for drugs
 */
export default Vue.extend({
  components: { BaseContentItem, DoceoImage, ContentItemDate },

  props: {
    title: {
      type: String,
      required: true,
    },
    items: Array as PropType<Array<ContentItemRelatedResult>>,
    searchTerm: {
      type: String,
      required: true,
    },
    contentType: {
      type: String,
      required: true,
    },
  },

  data: () => ({}),
  async created() {},
  computed: {},
  methods: {
    viewItem(item: ContentItemRelatedResult) {
      this.$router.push({
        name: "ContentItemDetails",
        params: { contentItemId: item.id },
      });
    },
  },
});
